<template>
  <div class="space-y-[6px] mt-[11px] pl-[18px]">
    <p class="text-[14px] font-semibold text-white">{{ title }}</p>
    <div
      class="custom-slider"
      ref="sliderRef"
      @mousedown="startDrag"
      @mousemove="drag"
      @mouseup="endDrag"
    >
      <img
        alt="volumn"
        :src="icon"
        class="absolute left-[5px] top-[25%] z-10"
      />
      <div
        class="slider-background-left bg-ems-main2 rounded-l-full"
        :style="{ width: thumbPosition + 'px' }"
      ></div>
      <div
        class="slider-background-right bg-ems-gray200 rounded-r-full"
        :style="{ width: sliderWidth - thumbPosition + 'px' }"
      ></div>
      <div
        class="slider-thumb bg-white shadow"
        :style="{ left: thumbPosition + 'px' }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import VueTypes from 'vue-types';
const props = defineProps({
  icon: VueTypes.string.def(''),
  title: VueTypes.string.def(''),
});
const sliderRef = ref(null);
const isDragging = ref(false);
const thumbPosition = ref(60);
const sliderWidth = ref(200);

const startDrag = (event) => {
  isDragging.value = true;
};

const drag = (event) => {
  if (isDragging.value) {
    const sliderRect = sliderRef.value.getBoundingClientRect();
    const newPosition = event.clientX - sliderRect.left;
    thumbPosition.value = Math.max(0, Math.min(sliderWidth.value, newPosition));
  }
};

const endDrag = () => {
  isDragging.value = false;
};
</script>

<style scoped>

.custom-slider {
  position: relative;
  width: 200px;
  height: 20px;
  cursor: pointer;
}

.slider-background-left,
.slider-background-right {
  position: absolute;
  top: 0;
  height: 100%;
}

.slider-background-left {
  left: 0;
}

.slider-background-right {
  right: 0;
}

.slider-thumb {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

</style>
